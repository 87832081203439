import React, { useState, useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap"

import AOS from "aos"
import "aos/dist/aos.css"

import { PredictionData } from "./data"
import PredictionAdmin from "@/components/prediction-admin"
import PredictionTopSection from "../prediction-top-sec"
import PredictionMobile from "@/components/prediction-mobile"

const Prediction = () => {

  useEffect(() => {
    AOS.init({
      once: true,
    })
    AOS.refresh()
  }, [])

  const [active, setActive] = useState(0)

  const { posts } = PredictionData;
  
  return (
    <section className="commonSection">
        <Container>
            <Row>
              <Col lg={12} className="tab-border">
                <ul className="chooseUs_title">
                  {posts.map(({ title }, index) => (
                    <li
                      key={index}
                      className={`${active === index ? "active" : " "}`}
                    >
                      <a
                        href="#"
                        onClick={e => {
                          e.preventDefault()
                          setActive(index)
                        }}
                      >
                        {title}
                      </a>
                    </li>
                  ))}
                </ul>
              </Col>
              <div className="commonSection">
                {posts.map((post, index) => {
                  return index === active ? (
                    <div
                      className="tab-pane fade show active animated fadeIn"
                      id={`tes_tab_${index}`}
                      key={index}
                    >
                      <PredictionTopSection post={post} index={index} />
                      { 0 === index % 2 ? <PredictionMobile /> : <PredictionAdmin /> }
                    </div>
                    ) : null
                })}
              </div>
            </Row>
        </Container>
    </section>
  )
}

export default Prediction
