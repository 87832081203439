import EventDashboard from "@/images/predictions/event_dashboard.png";
import PredictionActions from "@/images/predictions/pred_qn.png";
import PredictionCRUD from "@/images/predictions/pred_crud.png";
import PredictionQuestionsFilter from "@/images/predictions/pred_filter.png";
import EventParticipants from "@/images/predictions/event_participants.png";
import PredictionResult from "@/images/predictions/pred_rslt.png";
import PredictionProcessing from "@/images/predictions/pred_processing.png";
import PredictionAuto from "@/images/predictions/pred_auto.png";
import PredictionResultProcessing from "@/images/predictions/pred_rslt_processing.png";
import Winner from "@/images/predictions/winner.png";

export const AdminData = {
    adminSection: [
        {   
            sectionContent: {
                title:'Prediction Event Dashboard',
                subTitle: '',
                text: 'The Admin can view all the questions for each game under the Event.',
                image: EventDashboard,
            }
        },
        {   
            sectionContent: {
                title:'Prediction Questions Actions',
                subTitle: '',
                text: 'The Admin can Edit, Delete or Schedule the Questions to a date and time as required.',
                image: PredictionActions,
            }
        },
        {   
            sectionContent: {
                title:'Prediction Question CRUD ',
                subTitle: '',
                text: 'Setting the Date & Time, Adding Sponsor & Prizes, Publish or Keep it as draft.',
                image: PredictionCRUD,
            }
        },
        { 
            sectionContent: {
                title:'Prediction Questions Filter ',
                subTitle: '',
                text: 'Lists the Questions as per the filters selected, Active, Recent & Daily Prize questions.',
                image: PredictionQuestionsFilter,
            }  
        },
        {   
            sectionContent: {
                title:'Event Prediction Participants',
                subTitle: '',
                text: 'Listing all participants in an Event with their total points and with filters on the top.',
                image: EventParticipants,
            }
        },
        {   
            sectionContent: {
                title:'Match Prediction Result Processing',
                subTitle: '',
                text: 'User can process the results here and also select the Question from the filter whose result needs to be processed.',
                image: PredictionResult,
            }
        },
        {   
            sectionContent: {
                title:'Game Prediction Question Processing',
                subTitle: '',
                text: 'The Admin can process the results here for a selected question. Listing all the winners to the question and a CTA to notify them.',
                image: PredictionProcessing,
            }
        },
        {   
            sectionContent: {
                title:'Game Prediction Result Processing',
                subTitle: '',
                text: 'The auto feature enables picking the results dynamically as soon as the Match details are entered after the game ends.',
                image: PredictionAuto,
            }
        },
        // {   
        //     sectionContent: {
        //         title:'Game Prediction Result Processing',
        //         subTitle: '',
        //         text: 'Manually entering the results to process the predictions and get the correct answer winners',
        //         image: PredictionResultProcessing,
        //     }
        // },
        {   
            sectionContent: {
                title:'',
                subTitle: '',
                text: 'Multiple Winners for a particular question and the process to select a "Random Winner" from the list. ',
                image: Winner,
            }
        },
      ]
}