import React, { useState, Fragment } from "react"
import { Col } from "react-bootstrap"

import SectionTitle from "@/components/section-title"
import SectionContent from "@/components/section-content"

const PredictionTopSection = ({post, index}) => {

    const [active, setActive] = useState(0)

    return (
        <section>
            <Col className="text-center">
                <SectionTitle data={post} />
                <SectionContent data={post} />
            </Col>
            <Fragment>
              <Col className={`${
                0 === index % 2 ? " " : "flex-lg-row-reverse cust-sec-pad"
              }`}>
                <Col lg={12} data-aos="fade-up">
                  <div className="d-flex justify-content-center">
                    <img
                      src={post.image}
                      alt={`chose_img-${index}`}
                      height={`${0 === index % 2 ? '25%' : '60%'}`}
                      width={`${0 === index % 2 ? '25%' : '60%'}`}
                    />
                  </div>
                </Col>
              </Col>
            </Fragment>
        </section>
    )
}

export default PredictionTopSection