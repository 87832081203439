import React, { useEffect } from "react"

import { Col, Row } from "react-bootstrap"
import { PredictionQuestionData } from './data'
import AOS from "aos"
import "aos/dist/aos.css"
import loadable from "@loadable/component"

const ParticlesBg = loadable(() => import("particles-bg"))

const PredictionQuestion = () => {
    
    useEffect(() => {
        AOS.init()
        AOS.refresh()
      }, [])

    const { secTitle, secDesc, sectionImages } = PredictionQuestionData

    return (
        <section>
            <Col className="text-center">
                <Col className="pb-5">
                    <h2 className="sec_title">{secTitle}</h2>
                    <span className="sec_desc">{secDesc}</span>
                </Col>
                <Col className="d-flex">
                    <Row>
                    <ParticlesBg color="#a13eb4" type="cobweb" num={25} bg={true} />
                        {sectionImages.map((post, index) => {
                            return (
                                <Col lg={4} md={6} sm={12} key={index} data-aos="zoom-in-up" data-aos-duration="2000" className="pb-5" >
                                    <div>
                                        <img src={post.image} alt={`predction-images-${index}`} className='img-fluid'   loading="lazy" height='80%' width="80%"/>
                                    </div>
                                </Col>
                            )
                        })}
                    </Row>
                </Col>
            </Col>
        </section>
    )

}

export default PredictionQuestion