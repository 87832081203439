import React from "react"

import { Col } from "react-bootstrap"
import { AdminData } from "./data"
import FeatureSection from "@/components/feature-section"

const PredictionAdmin = () => {

    const { adminSection } = AdminData;

    return (
        <section>
            <Col className="text-center">
                {adminSection.map((post, i) => {
                    return (
                        <Col className="prediction-admin " key={i}>
                            <FeatureSection className='img-fluid' data={post} pos={i+1} img_resl={'predict_img_admin'} />
                        </Col>
                    )
                })}
            </Col>
        </section>
    )
}

export default PredictionAdmin