import WinningScore from "@/images/predictions/winning_score.png";
import POM from "@/images/predictions/pom.png";
import MatchWinner from "@/images/predictions/match_winner.png";
import MostRuns from "@/images/predictions/most_runs.png";
import Toss from "@/images/predictions/toss.png";
import MostWickets from "@/images/predictions/most_wickets.png";

export const PredictionQuestionData = {
    secTitle: 'Prediction Question Types',
    secDesc: 'Users can answer to the various questions assigned to a Match in the event.',
    sectionImages: [
      {
          image: WinningScore,
      },
      {
          image: POM,
      },
      {
          image: MatchWinner,
      },
      {
          image: MostRuns,
      },
      {
          image: MostWickets,
      },
      {
          image: Toss,
      },
    ],
}