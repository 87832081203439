import EventLanding from "@/images/predictions/event_landing.png";
import PredictionDashboard from "@/images/predictions/prediction_dashboard.png";
import PredictionFilter from "@/images/predictions/prediction_filter.png";
import PredictionListing from "@/images/predictions/prediction_listing.png";
import Leaderboard from "@/images/predictions/leaderboard.png";
import PredictionQuestions from "@/images/predictions/prediction_questions.png";

export const MobileData = {
    sectionContent: [
        {
            sectionContent: {
                title: 'Prediction Dashboard',
                subTitle: '',
                text: 'Dashboard for the selected Event where the Upcoming Predictions, Recent Predictions are listed along     with leader board, and recent winners.',
                image: PredictionDashboard,
            }
        },
        {
            sectionContent: {
                title: 'Leaderboard',
            subTitle: '',
            text: 'All the participants of the event prediction are listed in ascending order based on their points with the leaders listed at the very top. If the current user is the points leader, then a special animated trophy is shown to user.', 
            image: Leaderboard,
            }
        },
        {
            sectionContent: {
                title: 'Prediction Listing',
            subTitle: '',
            text: 'Page to view relevant Predictions for an Event and its matches. App users can select challenges that are ready for prediction before its time expires.',
            image: PredictionListing,
            }
        },
        {
            sectionContent: {
                title: 'Prediction Filter',
                subTitle: '',
                text: 'Users can filter the Prediction questions by the game for the event.',
                image: PredictionFilter,
            }
        },
        {
            sectionContent: {
            title: 'Event Landing Page',
            subTitle: '',
            text: 'App users see the Event Prediction on this page and the current Top 3 leaders of this event if available.',
            image: EventLanding,
            }
        },
        {
            sectionContent: {
                title: 'Prediction Questions',
            subTitle: '',
            text: 'Users can answer to the various challenges assigned to a Match in the event. There are several different types of challenges, viz pick from a team of 2, pick any player from the teams in a given match or from the whole teams in the event. There are cricket specific challenges to let users predict the winning team’s match score (runs and wickets) and winning teams margin (by runs or wickets) that gives higher points when predicted correctly. Also users may get points just by participating in a challenge even if they get their answers wrong.', 
            image: PredictionQuestions,
            }
            
        },
        ]
}