import React from "react"
import { Col } from "react-bootstrap"

import { MobileData } from "./data"
import FeatureSection from "@/components/feature-section"
import PredictionQuestion from "@/components/prediction-question"

const PredictionMobile = () => {

    const { sectionContent } = MobileData;
    const l = sectionContent.length

    return (
        <section className="row">
            {sectionContent.map((post, i) => {
              for (i; i < l; i++) {
                return (
                  <Col
                    lg={12} md={12} sm={12}
                    key={i}
                  >
                    <FeatureSection data={post} pos={i+1} img_resl={'predict_img_mobile'}/>
                  </Col>
                )
              }
              return null
            })}
            <PredictionQuestion />
        </section>
    )
}

export default PredictionMobile