import mobile from '@/images/predictions/mobile.png'
import lap from '@/images/predictions/lap.png'

export const PredictionData = {
    posts: [
        {
            title: 'Predictions User Module',
            content: "With the new Prediction feature in place, Users can Predict and Win prizes for the Questions set for the Event and the Games. Prizes would be as decided by the Sponsors for these questions.",
            image: mobile,
        },
        {
            title: 'Predictions Admin Module',
            content: "Scoreblox has reached a new milestone in the 1st quarter of 2023 with the Prediction Feature.Club admins      can enable Predict & Win module for an event and various prediction challenges can be set up for each game which            enables the app users to participate, predict and win points and associated prizes",
            image:lap,
        },
    ],
}
